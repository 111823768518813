/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import classNames from 'classnames';
import Button from 'components/core/button';
import CopyButton from 'components/core/copy-button';
import UserDetailsBasicInfoField from './field';

import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useUserDetailsPageStore } from '../provider/StoreProvider';
import { cutId } from 'utils/strings';
import { useCallback } from 'react';
import { useIntlUserVerificationStatuses } from 'hooks/intl/user-verification-statuses';
import { useIntlUserApproved } from 'hooks/intl/user-approved';
import { VerificationStatus } from 'api/auth';
import { useIntlUserEnabled } from 'hooks/intl/user-enabled';
import { FaPen } from 'react-icons/fa6';
import { useSidebarUserEdit } from 'components/sidebars/sidebar-user-edit';
import { UserModel } from 'models/UserModel';
import { useBaseStores } from 'providers/BaseStoresProvider';

const UserDetailsBasicInfo = observer(() => {
  const intl = useIntl();
  const userDetailsPageStore = useUserDetailsPageStore();

  const { notificationStore } = useBaseStores();

  const { details } = userDetailsPageStore;

  const { verificationStatusToIntlText } = useIntlUserVerificationStatuses();
  const { approvedBooleanToIntlText } = useIntlUserApproved();
  const { enabledBooleanToIntlText } = useIntlUserEnabled();

  const verificationStatusToClass = useCallback(
    (status: VerificationStatus) => {
      switch (status) {
        case VerificationStatus.GREEN:
          return 'text-status-approved';

        case VerificationStatus.PENDING:
        case VerificationStatus.PENDINGL2F:
          return 'text-system-warning';

        case VerificationStatus.REJECT:
        case VerificationStatus.RETRY:
          return 'text-system-error';

        case VerificationStatus.INIT:
        case VerificationStatus.UNDEFINED:
        default:
          return 'text-status-not_started';
      }
    },
    []
  );

  const onEditUser = useCallback(
    (user: UserModel) => {
      const saveAsync = async () => {
        await userDetailsPageStore.save(user);
        notificationStore.notify({
          title: intl.formatMessage({
            id: 'page.user_details.edit.notification',
            defaultMessage: 'User data updated successfully',
          }),
          type: 'success',
        });
      };

      saveAsync();
    },
    [intl, notificationStore, userDetailsPageStore]
  );

  const handleApproveStatus = useCallback(
    (approveStatus: boolean) => {
      userDetailsPageStore.adminApproveUser(approveStatus);
    },
    [userDetailsPageStore]
  );

  const { showEditUserSidebar } = useSidebarUserEdit({
    onChange: onEditUser,
  });

  if (!details) {
    return null;
  }

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.edit_btn}>
          <Button
            variant='secondary'
            onClick={() => showEditUserSidebar(details)}
          >
            <span>
              {intl.formatMessage({
                id: 'page.user_details.edit',
                defaultMessage: 'Edit',
              })}
            </span>

            <FaPen />
          </Button>
        </div>

        <span className={styles.name}>
          {details.firstName} {details.lastName}
        </span>

        <div className={styles.userId}>
          <span>{cutId(details.id)}</span>

          <CopyButton text={details.id} />
        </div>
      </div>

      <div className={styles.body}>
        <UserDetailsBasicInfoField
          label={intl.formatMessage({
            id: 'page.user_details.firstName',
            defaultMessage: 'First name',
          })}
          value={details.firstName}
        />

        <UserDetailsBasicInfoField
          label={intl.formatMessage({
            id: 'page.user_details.lastName',
            defaultMessage: 'Last name',
          })}
          value={details.lastName}
        />

        <UserDetailsBasicInfoField
          label={intl.formatMessage({
            id: 'page.user_details.mobile',
            defaultMessage: 'Mobile number',
          })}
          value={details.phone}
        />

        <UserDetailsBasicInfoField
          label={intl.formatMessage({
            id: 'page.user_details.email',
            defaultMessage: 'Email',
          })}
          value={details.email}
        />

        {(!!details.residentialAddress?.country ||
          !!details.residentialAddress?.town) && (
          <div className={styles.body_group}>
            <UserDetailsBasicInfoField
              label={intl.formatMessage({
                id: 'page.user_details.country',
                defaultMessage: 'Country',
              })}
              value={details.residentialAddress.country}
            />

            <UserDetailsBasicInfoField
              label={intl.formatMessage({
                id: 'page.user_details.city',
                defaultMessage: 'City',
              })}
              value={details.residentialAddress.town}
            />
          </div>
        )}

        <UserDetailsBasicInfoField
          label={intl.formatMessage({
            id: 'page.user_details.street',
            defaultMessage: 'Street',
          })}
          value={details.residentialAddress?.street}
        />

        <UserDetailsBasicInfoField
          label={intl.formatMessage({
            id: 'page.user_details.sub_street',
            defaultMessage: 'Sub street',
          })}
          value={details.residentialAddress?.subStreet}
        />

        {(!!details.residentialAddress?.buildingNumber ||
          !!details.residentialAddress?.buildingName) && (
          <div className={styles.body_group}>
            <UserDetailsBasicInfoField
              label={intl.formatMessage({
                id: 'page.user_details.building_number',
                defaultMessage: 'Building number',
              })}
              value={details.residentialAddress.buildingNumber}
            />

            <UserDetailsBasicInfoField
              label={intl.formatMessage({
                id: 'page.user_details.building_name',
                defaultMessage: 'Building name',
              })}
              value={details.residentialAddress.buildingName}
            />
          </div>
        )}

        {(!!details.residentialAddress?.flatNumber ||
          !!details.residentialAddress?.postCode) && (
          <div className={styles.body_group}>
            <UserDetailsBasicInfoField
              label={intl.formatMessage({
                id: 'page.user_details.flat_number',
                defaultMessage: 'Flat number',
              })}
              value={details.residentialAddress.flatNumber}
            />

            <UserDetailsBasicInfoField
              label={intl.formatMessage({
                id: 'page.user_details.post_code',
                defaultMessage: 'Post code',
              })}
              value={details.residentialAddress.postCode}
            />
          </div>
        )}

        <div className={styles.body_group}>
          <UserDetailsBasicInfoField
            label={intl.formatMessage({
              id: 'page.user_details.status',
              defaultMessage: 'Status',
            })}
            value={approvedBooleanToIntlText(details.approved)}
            className={classNames(
              {
                'text-system-success': details.approved,
              },
              { 'text-system-warning': !details.approved }
            )}
            disableCopy
          />

          <UserDetailsBasicInfoField
            label={intl.formatMessage({
              id: 'page.user_details.kycKyb',
              defaultMessage: 'KYC/KYB',
            })}
            value={verificationStatusToIntlText(details.verificationStatus)}
            className={classNames(
              verificationStatusToClass(details.verificationStatus)
            )}
            disableCopy
          />
        </div>

        <div className={styles.body_group}>
          <UserDetailsBasicInfoField
            label={intl.formatMessage({
              id: 'page.user_details.partner',
              defaultMessage: 'Partner',
            })}
            value={details.partner}
            disableCopy
          />

          <UserDetailsBasicInfoField
            label={intl.formatMessage({
              id: 'page.user_details.account_status',
              defaultMessage: 'Account status',
            })}
            value={enabledBooleanToIntlText(details.enabled)}
            disableCopy
          />
        </div>

        <div className={styles.body_group}>
          <Button
            variant={details.approved ? 'negative' : 'positive'}
            onClick={() => {
              handleApproveStatus(details.approved);
            }}
          >
            {details.approved
              ? intl.formatMessage({
                  id: 'page.user_details.revokeApproval',
                  defaultMessage: 'Revoke Approval',
                })
              : intl.formatMessage({
                  id: 'page.user_details.change_status',
                  defaultMessage: 'Change Status',
                })}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default UserDetailsBasicInfo;
